.custom__iptv__table {
  border-collapse: separate;
  border-spacing: 0.1rem 0;
  width: 100%;
  position: relative;
  // margin-top: 2rem;
  border-radius: 4px;
  // overflow: hidden;

  td {
    padding: 0.6rem 6px;
    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 450px;
    text-align: left;
    min-height: 100px;
    word-wrap: break-word;
  }
  td:last-child {
    overflow: visible;
    text-align: left;
  }
  th {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    position: sticky;
    top: 44px;
    font-size: 0.8rem;
    text-align: left;
    background: var(--darkBack);
    color: white;
    z-index: 10;
    cursor: pointer;
    opacity: 0.8;
    &:last-child {
      text-align: left;
      padding-right: 36px;
      border-radius: 0 4px 4px 0;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
  }
  .selected {
    opacity: 1;
    // &::before{
    //     content: url('../../assets/up.png');
    //     position: absolute;
    //     right: 4px;
    //     top: 0%;
    //     transform: scale(0.6);
    //     // transform: translateY(-58%);
    //     // width: 12px;
    // }
    &::after {
      content: url("../../assets/sort.png");
      position: absolute;
      right: 4px;
      top: 52%;
      transform: translateY(-50%) scale(0.8);
      opacity: 0.8;
      // transform: scale(0.6);
      // width: 12px;
    }
  }
  tr:nth-child(even) {
    background-color: var(--tableContrast);
  }
  tr {
    // position: relative;
    z-index: 0;
    transition: 0.05s ease-in;
    &:hover {
      background: var(--tableHover);
    }
  }
  td:last-child {
    position: relative;
    text-align: left;
    &:hover span {
      opacity: 0;
    }
  }
  .action__items {
    width: 320px;
    text-align: right;
    // display: none;
    opacity: 0;
    position: absolute;
    z-index: 5;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    // transition: 0.05s ease-in;
  }
  tr:hover .action__items {
    opacity: 1;
  }
  img {
    width: 32px;
  }
  button {
    font-size: 0.7rem;
    display: inline-block;
    margin-left: 0.25rem;
  }
  td.label__align {
    text-align: center;
  }
}

.custom__iptv__table th:hover {
  opacity: 0.9;
}

.custom__iptv__table .no__sort {
  cursor: default;
  &:hover {
    opacity: 0.8;
  }
}

//pagination
.custom__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 1rem;
  ul {
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      padding: 4px;
      border: 1px solid var(--lightBack);
      border-radius: 50%;
      opacity: 0.8;
      &:disabled {
        opacity: 0.3;
      }
    }

    span {
      font-weight: bold;
      font-size: 0.8rem;
      margin: 0 0.5rem;
    }
  }
}

//custom dropdown
.custom__table__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  span {
    font-size: 0.8rem;
    select {
      margin: 0 0.25rem;
      padding: 0.25rem 1rem 0.25rem 0.25rem;
      border: 1px solid var(--lightBack);
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      background: 0 0;
    }
  }
  .table__summary {
    margin: 0.25rem 0;
    font-size: 0.8rem;
    font-weight: bold;
  }
}
.table__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .table__summary {
    margin-right: 1rem;
  }
}
