.noPadding{
    padding: 0!important;
}
.paddingLeft{
    padding-left: 0!important;
}
.paddingRight{
    padding-right: 0!important;
}
.clearfix{
    float: none;
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}