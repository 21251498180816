img {
  max-width: 100%;
}
.show__mobile {
  display: none !important;
}

@media (max-width: 768px) {
  .show__mobile {
    display: inherit !important;
  }
  .hide__mobile {
    display: none !important;
  }
}
@media print {
  button {
    display: none;
  }
  tr {
    td,
    th {
      text-align: left !important;
    }
  }
  .cms__content {
    padding-top: 1rem !important;
    margin-top: 0 !important;
    background: #fff !important;
  }
  .report__header {
    display: flex !important;
  }
  .custom__field {
    border: none !important;
    background: 0 0 !important;
  }
  // .report_selection_label {
  //     display: none !important;
  // }
  select::-ms-expand {
    display: none;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
  .onlyPrint {
    display: block !important;
  }
}
