.side__slider{
    width: 300px;
    height: 100vh;
    overflow-y: auto;
    padding: 2.65rem 15px;
    padding-bottom: 1rem;
    background: var(--white);
    position: fixed;
    // right: 0;
    top: 0;
    h6{
        margin: 0;
        font-size: 0.8rem;
    }
    .search__container{
        width: 100%;
    }
    .side__slider--content{
        position: relative;
        padding: 0;
        padding-top: 1rem;
        .close__button{
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            opacity: 0.8;
            padding: 0;
            background: transparent;
            border: none;
            transition: 0.4s;
            &:hover{
                opacity: 1;
            }
        }
        label{
            margin: 0.5rem 0 0 0;
        }
        .send__all--content{
            label{
                margin: 0;
            }
        }
        .search__radio{
            display: flex;
            align-items: center;
            label{
                margin: 0 0.5rem 0 0.15rem ;
            }
        }
        hr:first-child{
            margin-bottom: 0;
        }
    }
    .search__results{
        height: 200px;
        overflow-y: auto;
    }
    .custom__iptv__table{
        border-spacing: 0;
        td, th{
            padding: 4px;
            font-weight: normal;
            top: 0;
            &:last-child{
                text-align: left;
                padding-right: 4px;
            }
            &:first-child{
                width: 10px;
            }
        }
    }
    .sub__accordion{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .search__inner{
        padding-top: 1rem;
    }
    .search__subscriber{
        background: var(--offwhite);
        padding: 1rem 15px;
        border-radius: 4px;
        margin-bottom: 1rem;
    }
    .down__arrow{
        width: 20px;
        transform: rotate(90deg);
    }
    .up__arrow{
        width: 20px;
        transform: rotate(-90deg);
    }
}

