.wizard__navigation {
    ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: var(--light-highlight-color);
        border-radius: 4px;
        overflow: hidden;
        li {
            padding: 0.5rem 2rem;
            background: var(--light-highlight-color);
            cursor: not-allowed;
            color: var(--offwhite);
            position: relative;
            // margin-right: 18px;
            text-transform: uppercase;
            &::before {
                width: 0;
                height: 0;
                border-top: 18px inset transparent;
                border-bottom: 18px inset transparent;
                border-left: 18px solid #ffffff;
                position: absolute;
                content: "";
                bottom: 0;
                right: -19px;
                z-index: 2;
            }
            &::after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                right: -18px;
                border-style: solid;
                border-width: 18px 0 18px 18px;
                border-color: transparent transparent transparent var(--light-highlight-color);
                bottom: 0px;
                z-index: 2;
            }
        }
        .completed-step {
            background: var(--highlight-color);
            cursor: pointer;
            &::after {
                border-color: transparent transparent transparent var(--highlight-color);
                z-index: 2;
            }
        }
    }
}
.wizard__sections {
    padding: 1rem 0;
}
