:root{
    --themeGradient: -webkit-linear-gradient(#36cfda, #5b84e3);
    --highlight-color: #2980b9;
    --light-highlight-color: #62a5d1;
    --danger-color: #c0392b;
    --offwhite: #f5f5f5;
    --darkBack: #676874;
    --lightBack: #b1b3c1;
    --white: #fff;
    --nav-white: #fff;
    --tableContrast: rgba(0, 0, 0, 0.02);
    --tableHover: rgba(0, 0, 0, 0.1);
    --green: #27ae60;
    --light-green: #74d39c;
    --orange: #e67e22;
    // font colors
    --default-dark-font: #665f82;
    --nav-font :  #665f82;
    --nav-button: #2980b9;
}


