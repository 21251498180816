@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }
   
    // Output standard non-prefixed declaration
    #{$property}: $value;
}
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
      -webkit-box-shadow:inset $top $left $blur $color;
      -moz-box-shadow:inset $top $left $blur $color;
      box-shadow:inset $top $left $blur $color;
    } @else {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
    }
}

