.custom__field {
  display: block;
  // margin: 0.25rem 0 1rem 0;
  width: 100%;
  background: 0 0;
  border: 1px solid var(--lightBack);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  transition: 0.4s ease-in;
  resize: none;
  &:active,
  &:focus {
    border-color: var(--darkBack);
    background: var(--white);
    outline: none;
  }
  &:disabled {
    background: var(--tableContrast);
    border-color: var(--lightBack);
    opacity: 0.7;
    cursor: not-allowed;
  }
}
.custom__field.error__field {
  border: 1px solid var(--danger-color);
}
select.custom__field {
  padding: 0.35rem 1rem;
}
textarea {
  resize: none;
}
.filter__group {
  .custom__field {
    // margin: 0.25rem 0 0.5rem 0;
  }
}
form {
  label {
    margin: 0;
    font-size: 0.8rem;
    font-weight: bold;
    &::after {
      content: "*";
      margin-left: 0.5rem;
      color: var(--danger-color);
    }
  }
  // All non mandatory fields in form should have this class to remove the * (-- only for labels)
  .not__required::after {
    content: "";
    display: none;
  }
}
.form__style {
  padding: 0 !important;
  margin: 1rem 0;
  border-radius: 4px;
}
.form__style .row div {
  position: relative;
  margin-bottom: 12px;
  .error__msg {
    position: absolute;
    color: var(--danger-color);
    font-size: 0.8rem;
    &:before {
      content: "*";
    }
  }
}

// slider styles
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2.25px;
  bottom: 2.25px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--green);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--green);
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.border__form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 1rem 15px;
  width: calc(100% - 30px) !important;
  background: 0 0;
  border: 1px solid var(--lightBack);
  padding: 0.5rem;
  border-radius: 4px;
}

.combo__input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  input {
    width: 79%;
  }
  select {
    width: 19%;
    padding: 0.35rem 0.5rem;
  }
}

@media (max-width: 768px) {
  .combo__input {
    input {
      width: 100%;
    }
    select {
      width: 100%;
      padding: 0.35rem 1rem;
    }
  }
}

.search__container {
  // border: 2px solid red;
  position: relative;
  width: 200px;
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;
  input {
    // position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    font-size: 0.8rem;
  }
  button {
    position: absolute;
    background: transparent;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    padding: 2px;
    border: none;
    top: -2px;
    right: 0;
    opacity: 0.4;
    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      background: transparent;
      outline: transparent;
    }
  }
}

.report_selection {
  display: inline-block;
  width: 100%;
}

.report_selection_label {
  display: inline-block;
  width: 20%;
}

.report_selection_block {
  display: flex;
  justify-content: center;
  align-items: center;
}
